import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { TegelModule } from '@scania/tegel-angular-17';

@Component({
  selector: 'app-navbar',
  standalone: true,
  imports: [TegelModule],
  templateUrl: './navbar.component.html',
  styleUrl: './navbar.component.css'
})
export class NavbarComponent {

  constructor(private router: Router) { }

  navigateToDashDesp3(): void {
    this.router.navigate(['/dashdesp3']);
      console.log('carregado');
  }
  navigateToHome(): void {
    this.router.navigate(['']);
  }

  navigateToCalculosGerais(): void {
    this.router.navigate(['/calculos-gerais']);
  }

  navigateToCalculoProvisao(): void {
    this.router.navigate(['/calculos-provisao']);
  }

  navigateToAdmin(): void {
    this.router.navigate(['/admin']);
  }

  navigateToProjecao(): void {
    this.router.navigate(['/projecao']);
  }

  navigateToContas(): void {
    this.router.navigate(['/contas']);
  }

  navigateToCentroCustos(): void {
    this.router.navigate(['/centro-custo']);
  }

  navigateToCiclo(): void {
    this.router.navigate(['/ciclos'])
  }

}
